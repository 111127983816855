<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- 설비목록 -->
        <!-- 정비계획할 설비를 추가하세요. 저장할 시 정비결과를 등록 할 수 있습니다. -->
        <c-table
          ref="table"
          title="LBL0003696"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :isExcelDown="false"
          :editable="editable"
          :noDataLabel="$message('MSG0001063')"
          selection="multiple"
          cardClass="topcolor-orange"
          @linkClick="linkClick"
          rowKey="minEquipmentMaintenanceId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 추가 -->
              <c-btn v-if="editable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="add" />
              <!-- 삭제 -->
              <c-btn v-if="editable && grid.data.length > 0" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="remove" />
              <!-- 저장 -->
              <c-btn
                v-if="editable && grid.data.length > 0"
                :url="insertUrl"
                :isSubmit="isSave"
                :param="grid.data"
                mappingType="POST"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-btn
                class="tableinnerBtn tableinnerUserSearch"
                :class="{'tableinnerUserSearch-left': true,  'tableinnerUserSearch-right': true }"
                flat
                color="blue-6"
                :label="props.row.sopMocName"
                icon="search"
                @click.stop="linkClick(props.row)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import { v4 as uuidv4 } from 'uuid';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-maintenance-plan',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        disabled: false,
        minEquipmentMaintenanceId: '',
        checkDate: '',
        selectData: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentMaintenanceId: '',
        plantCd: '',
        equipmentCd: '',
        maintenanceStatusCd: '',
        equipmentTypeCd: '',
        equipmentCheckTypeCd: '',
        memDeptEntrantUserId: '',
        maintenanceDueDate: '',
        maintenanceDueUserId: '',
        sopMocId: '',
        sopMocName: '',
        maintenanceUserId: '',
        maintenanceQualifications: '',
        maintenanceDate: '',
        maintenanceResultSummary: '',
        inspResultAfterMemCd: '',
        maintenanceCompleteFlag: '',
        noPlanResultEnrollFlag: '',
        maintenanceCause: '',
        maintenanceContent: '',
        maintenanceTime: '',
        stopDrivingTime: '',
        reviewOpinionOfMananger: '',
      },
      grid: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 설비유형
            label: 'LBL0000633',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            // 설비관리번호
            label: 'LBL0000631',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0000632',
            style: 'width:120px',
            align: 'left',
            sortable: false
          },
          {
            name: 'sopMocName',
            field: 'sopMocName',
            // MOC 번호
            label: 'LBLMOCNO',
            align: 'center',
            style: 'width:180px',
            sortable: false,
            type: "custom",
          },
          {
            name: 'equipmentCheckTypeCd',
            field: 'equipmentCheckTypeCd',
            // 정비유형
            label: 'LBL0003618',
            required: true,
            align: 'center',
            style: 'width:130px',
            type: 'select',
            setHeader: true,
            comboItems: [
              { code: 'MMKC00005', codeName: '예방정비' },
              { code: 'MMKC00010', codeName: '예측정비' },
              { code: 'MMKC00015', codeName: '고장정비' },
              { code: 'MMKC00020', codeName: '운전정비' },
            ],
            sortable: false,
          },
          {
            name: 'maintenanceDueUserName',
            field: 'maintenanceDueUserName',
            // 정비예정자
            label: 'LBL0003613',
            required: true,
            align: 'center',
            style: 'width:120px',
            setHeader: true,
            type: 'user',
            userId: 'maintenanceDueUserId',
            sortable: false,
          },
          {
            name: 'maintenanceDueDate',
            field: 'maintenanceDueDate',
            // 정비예정일
            label: 'LBL0003612',
            required: true,
            setHeader: true,
            align: 'center',
            style: 'width:150px',
            type: 'date',
            sortable: false,
          },
          {
            name: 'memDeptEntrantUserName',
            field: 'memDeptEntrantUserName',
            // 정비부서입회자
            label: 'LBL0003614',
            align: 'center',
            style: 'width:120px',
            setHeader: true,
            type: 'user',
            userId: 'memDeptEntrantUserId',
            sortable: false,
          },
          {
            name: 'pmCycleName',
            field: 'pmCycleName',
            // 예방정비주기
            label: 'LBL0003758',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'recentMaintainDate',
            field: 'recentMaintainDate',
            // 최근정비일
            label: 'LBL0003759',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            // 관련법규
            label: 'LBL0003610',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
        ],
        data: [],
        height: '700px'
      },
      title: '',

      insertUrl: '',
      updateUrl: '',
      completeUrl: '',
      deleteUrl: '',
      disabled: false,
      updateMode: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isSave: false,
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.min.maintenance.plan.insert.url;
      this.deleteUrl = transactionConfig.sop.min.maintenance.plan.delete.url;
      this.completeUrl = transactionConfig.sop.min.maintenance.plan.complete.url;

      if (this.popupParam.selectData.length > 0) {
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.grid.data.splice(0, 0, {
            minEquipmentMaintenanceId: uuidv4(),
            plantCd: _item.plantCd,
            plantName: _item.plantName,
            memDeptEntrantUserId: '',
            memDeptEntrantUserName: '',  
            sopMocId: '',
            sopMocName: '',
            equipmentCheckTypeCd: null,
            maintenanceStatusCd: 'MCSC000005',
            maintenanceDueUserName: '', 
            maintenanceDueUserId: '',
            maintenanceDueDate: '',
            equipmentTypeCd: _item.equipmentTypeCd, 
            equipmentTypeName: _item.equipmentTypeName,
            equipmentNo: this.data.equipmentNo,
            equipmentCd: _item.equipmentCd, 
            equipmentName: _item.equipmentName,
            pmCycleName: _item.pmCycleName,
            pmCycleCd: _item.pmCycleCd,
            recentMaintainDate: _item.recentMaintainDate,
            noPlanResultEnrollFlag: 'N',
            relatedLaws: _item.relatedLaws,
            relatedLawsName: _item.relatedLawsName,
            nextMonth: _item.nextMonth,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            editFlag: 'C',
          })  
        })
      }
      this.title = this.popupParam.title;

      // list setting
      this.title = this.popupParam.title;
      // this.getList();
    },
    getList() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.equipmentCd, this.popupParam.checkDate);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          this.updateMode = true;
        },);
      }
    },
    add() {
      this.popupOptions.title = 'LBL0003710'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple',
        title: '정비'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.grid.data.splice(0, 0, {
            minEquipmentMaintenanceId: uuidv4(),
            plantCd: _item.plantCd,
            plantName: _item.plantName,
            memDeptEntrantUserId: '',
            memDeptEntrantUserName: '',  
            sopMocId: '',
            sopMocName: '',
            equipmentCheckTypeCd: null,
            maintenanceStatusCd: 'MCSC000005',
            maintenanceDueUserName: '', 
            maintenanceDueUserId: '',
            maintenanceDueDate: '',
            equipmentTypeCd: _item.equipmentTypeCd, 
            equipmentTypeName: _item.equipmentTypeName,
            equipmentNo: _item.equipmentNo,
            equipmentCd: _item.equipmentCd, 
            equipmentName: _item.equipmentName,
            pmCycleName: _item.pmCycleName,
            pmCycleCd: _item.pmCycleCd,
            recentMaintainDate: _item.recentMaintainDate,
            noPlanResultEnrollFlag: 'N',
            relatedLaws: _item.relatedLaws,
            relatedLawsName: _item.relatedLawsName,
            nextMonth: _item.nextMonth,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            editFlag: 'C',
          })
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
            this.grid.data = this.$_.reject(this.grid.data, { minEquipmentMaintenanceId: item.minEquipmentMaintenanceId })
          })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveInspection() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      this.emit('SAVE');
    },
    select(type) {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 
            'MSGNOITEMS', // 선택된 항목이 없습니다. // 하나 이상 선택하세요
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      if (type === 'USER') {
        this.popupOptions.title = 'LBL0003760'; // 정비 예정자 검색
        this.popupOptions.param = {
          type: 'multiple'
        };
        this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
        this.popupOptions.width = '50%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeUserPopup;
      }
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (!data) return;

      this.$_.forEach(this.grid.data, _item => {
        _item.chkUserNm = data[0].userNm;
        _item.chkUserId = data[0].userId;
      })
    },
    linkClick(row) {
      this.popupOptions.title = "LBL0003687"; // 설비관련 MOC 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.row = row;
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.row.sopMocName = data[0].mocTypeName + ' / ' + data[0].mocNo
        this.row.sopMocId = data[0].sopMocId
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
  }
};
</script>
